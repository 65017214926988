import React, { useState } from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import { withPreview } from "gatsby-source-prismic";

// Components
import Gallery from "../components/images/gallery";
import Mailchimp from "../components/mailchimp/mailchimp";

const Page = styled.div`
  margin: 150px auto 78px auto;

  @media (max-width: 767px) {
    margin: 76px 0 40px 0;
    padding: 0 20px;
  }

  & a:hover {
    color: #d7490c;
  }
`;

const ContentContainer = styled.div`
  max-width: ${props => (props.maxWidth ? props.maxWidth : `1240px`)};

  margin: 0 auto;
  padding: ${props => (props.paddingDesktop ? props.paddingDesktop : `0 40px`)};

  @media (max-width: 767px) {
    padding: ${props => (props.paddingMobile ? props.paddingMobile : `0`)};
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: ${props => props.grid};
  grid-column-gap: 120px;

  @media (max-width: 1000px) {
    grid-column-gap: 40px;
  }

  @media (max-width: 767px) {
    grid-template-columns: ${props =>
      props.gridMobile ? props.gridMobile : `1fr`};
    grid-column-gap: 20px;
  }
`;

const Column = styled.div`
  grid-column: ${props => props.spanDesktop};

  @media (max-width: 767px) {
    grid-column: 1;

    & img {
      margin: 0 auto 35px auto;
    }
  }
`;

const TextContainer = styled.div`
  max-width: 700px;

  margin-top: -8px;

  @media (max-width: 767px) {
    margin-top: 0;
  }

  & h1,
  & p {
    font-family: "CaslonDoric-Medium", "Helvetica Neue", "Lucida Grande",
      sans-serif;
  }

  & p {
    font-size: 19px;
    line-height: 28px;
    letter-spacing: 0.06em;

    @media (max-width: 1000px) {
      font-size: 16px;
      line-height: 24px;
    }

    @media (max-width: 600px) {
      font-size: 15px;
      line-height: 22px;
    }
  }

  & h1 {
    @media (max-width: 1000px) {
      font-size: 20px;
      line-height: 28px;
    }

    @media (max-width: 767px) {
      font-size: 18px;
      line-height: 24px;
    }
  }

  & .pamphlet-text {
    margin: 30px 0 39px 0;

    @media (max-width: 767px) {
      margin: 30px 0 29px 0;
    }
  }
`;

const OtherPamphlets = styled.div`
  & h2 {
    font-family: "CaslonDoric-Medium", "Helvetica Neue", "Lucida Grande",
      sans-serif;

    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.06em;

    margin: 15px 0 0 0;
  }

  @media (max-width: 767px) {
    max-width: 280px;

    margin: 0 auto 40px auto;

    &:last-of-type {
      margin: 0 auto;
    }
  }
`;

const Downloads = styled.div`
  max-width: 700px;

  & button {
    color: #d7490c;
    letter-spacing: 0.06em;

    &:hover {
      color: #000;
    }

    @media (max-width: 1000px) {
      font-size: 16px;
      line-height: 24px;
    }

    @media (max-width: 600px) {
      font-size: 15px;
      line-height: 22px;
    }
  }
  & p {
    font-size: 19px;
    line-height: 28px;
    letter-spacing: 0.06em;

    @media (max-width: 1000px) {
      font-size: 16px;
      line-height: 24px;
    }

    @media (max-width: 600px) {
      font-size: 15px;
      line-height: 22px;
    }
  }
`;

const DownloadContainer = styled.div`
  display: ${props => (props.isOpen ? `block` : `none`)};
`;

const Pamphlet = ({ data }) => {
  const [isDownloadBoxVisible, setIsDownloadBoxVisible] = useState(false);

  const otherPamphlets = data.allPrismicPamphlet.edges.map((content, index) => (
    <OtherPamphlets key={`other_pamphlets_${index}`}>
      <Link to={`/pamphlets-for-culture/${content.node.uid}`}>
        {data.prismicPamphlet.data.image !== null && (
          <img
            srcSet={content.node.data.image.fluid.srcSetWebp}
            src={content.node.data.image.fluid.src}
            alt={content.node.data.image.alt}
            loading="lazy"
          />
        )}
        <h2>{content.node.data.title.text}</h2>
      </Link>
    </OtherPamphlets>
  ));

  const seoText = `${data.prismicPamphlet.data.title.text.substring(0, 99)}...`;

  return (
    <Page>
      <Helmet
        title={`${data.prismicPamphlet.data.title.text} – The Seeking State`}
        meta={[
          {
            name: "description",
            content: `${seoText}`,
          },
          {
            name: "og:image",
            content: `${data.prismicPamphlet.data.image.url}`,
          },
          {
            name: "og:image:secure_url",
            content: `${data.prismicPamphlet.data.image.url}`,
          },
          {
            name: "og:description",
            content: `${seoText}`,
          },
          {
            name: "og:image:width",
            content: `1200`,
          },
          {
            name: "og:image:height",
            content: `630`,
          },
          {
            name: "og:locale",
            content: `en`,
          },
          {
            name: "twitter:title",
            content: `The Seeking State`,
          },
          {
            name: "twitter:description",
            content: `${seoText}`,
          },
          {
            name: "twitter:card",
            content: `summary_large_image`,
          },
          {
            name: "twitter:image",
            content: `${data.prismicPamphlet.data.image.url}`,
          },
        ]}
      />

      <ContentContainer>
        <Grid grid={`repeat(3, 1fr)`}>
          <Column spanDesktop={`1`} spanMobile={`3`}>
            {data.prismicPamphlet.data.image !== null && (
              <img
                srcSet={data.prismicPamphlet.data.image.fluid.srcSetWebp}
                src={data.prismicPamphlet.data.image.fluid.src}
                alt={data.prismicPamphlet.data.image.alt}
              />
            )}
          </Column>

          <Column spanDesktop={`2 / 4`} spanMobile={`3`}>
            <TextContainer>
              <div
                dangerouslySetInnerHTML={{
                  __html: data.prismicPamphlet.data.title.html,
                }}
              />
              <div
                className="pamphlet-text"
                dangerouslySetInnerHTML={{
                  __html: data.prismicPamphlet.data.text.html,
                }}
              />
            </TextContainer>

            {data.prismicPamphlet.data.display_mailchimp_signup === true && (
              <Downloads>
                <button
                  onClick={() => setIsDownloadBoxVisible(!isDownloadBoxVisible)}
                >
                  Get Your Free Copy
                </button>
                <DownloadContainer isOpen={isDownloadBoxVisible}>
                  <Mailchimp
                    mailchimpText={data.prismicMailchimp.data}
                    tag={data.prismicPamphlet.data.title.text}
                  />
                </DownloadContainer>
              </Downloads>
            )}
          </Column>
        </Grid>
      </ContentContainer>

      <ContentContainer
        paddingDesktop={`85px 0`}
        maxWidth={`100%`}
        paddingMobile={`40px 0`}
      >
        <Gallery data={data.prismicPamphlet.data.gallery} />
      </ContentContainer>

      <ContentContainer>
        <Grid grid={`repeat(3, 1fr)`} gridMobile={`repeat(2, 1fr)`}>
          {otherPamphlets}
        </Grid>
      </ContentContainer>
    </Page>
  );
};

export default withPreview(Pamphlet);

export const query = graphql`
  query Pamphlet($uid: String!) {
    prismicPamphlet(uid: { eq: $uid }) {
      data {
        title {
          html
          text
        }
        text {
          html
          text
        }
        image {
          url
          alt
          fluid {
            srcSetWebp
            src
          }
        }
        display_mailchimp_signup
        gallery {
          gallery_image {
            url
            alt
            fluid {
              srcSetWebp
              src
            }
          }
        }
      }
    }
    allPrismicPamphlet(
      sort: { order: ASC, fields: data___title___html }
      filter: { uid: { ne: $uid } }
    ) {
      edges {
        node {
          uid
          data {
            title {
              text
            }
            image {
              fluid {
                srcSetWebp
                src
              }
            }
          }
        }
      }
    }
    prismicMailchimp {
      data {
        signup_text {
          html
        }
        after_signup_text {
          html
        }
      }
    }
  }
`;
