import React from "react";
import styled from "styled-components";

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  box-sizing: content-box;

  & div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: baseline;

    min-width: 300px;
    width: calc(50% - 15px);
    border-bottom: 2px solid #000;

    margin: 0 0 30px 0;

    @media (max-width: 1050px) {
      width: 100%;
    }
  }

  & .address-container {
    min-width: 300px;
    width: calc(100% - 90px);

    @media (max-width: 767px) {
      width: 100%;
    }
  }

  & input {
    width: calc(100% - 100px);

    font-family: "CaslonDoric-Medium", "Helvetica Neue", "Lucida Grande",
      sans-serif;
    letter-spacing: 0.06em;

    padding: 0;
    margin: 0;
    background-color: transparent;
    -webkit-appearance: none;

    border: 0;

    &:focus {
      outline: none;
    }

    position: relative;
    line-height: 1;

    @media (max-width: 767px) {
      width: 100%;
    }

    @media (max-width: 600px) {
      font-size: 15px;
      line-height: 34px;
    }
  }

  & label {
    letter-spacing: 0.06em;

    @media (max-width: 1000px) {
      font-size: 16px;
      line-height: 24px;
    }

    @media (max-width: 600px) {
      font-size: 15px;
      line-height: 22px;
    }
  }

  & button {
    @media (max-width: 1000px) {
      font-size: 16px;
      line-height: 24px;
    }

    @media (max-width: 600px) {
      font-size: 15px;
      line-height: 22px;
    }
  }
`;

const MailchimpFormContainer = styled.div`
  & .signup-text {
    margin: 35px 0 39px 0;
  }

  & input {
    ::-webkit-input-placeholder {
      color: #9c9c9c;

      font-family: "CaslonDoric-Medium", "Helvetica Neue", "Lucida Grande",
        sans-serif;
      font-size: 12px;
      line-height: 34px;
    }
    ::-moz-placeholder {
      color: #9c9c9c;

      font-family: "CaslonDoric-Medium", "Helvetica Neue", "Lucida Grande",
        sans-serif;
      font-size: 12px;
      line-height: 34px;
    }
    :-ms-input-placeholder {
      &color: #9c9c9c;

      font-family: "CaslonDoric-Medium", "Helvetica Neue", "Lucida Grande",
        sans-serif;
      font-size: 12px;
      line-height: 34px;
    }
    :-moz-placeholder {
      color: #9c9c9c;

      font-family: "CaslonDoric-Medium", "Helvetica Neue", "Lucida Grande",
        sans-serif;
      font-size: 12px;
      line-height: 34px;
    }
    ::placeholder {
      color: #9c9c9c;

      font-family: "CaslonDoric-Medium", "Helvetica Neue", "Lucida Grande",
        sans-serif;
      font-size: 12px;
      line-height: 34px;
    }
  }
`;

const ThankYouMessage = styled.div`
  position: relative;

  margin: 1em 0;

  & a {
    border-bottom: 2px solid #000;
  }

  font-size: 19px;
  line-height: 28px;
  letter-spacing: 0.06em;

  @media (max-width: 1000px) {
    font-size: 16px;
    line-height: 24px;
  }

  @media (max-width: 600px) {
    font-size: 15px;
    line-height: 22px;
  }
`;

const SignUpButton = styled.button`
  font-family: "CaslonDoric-Medium", "Helvetica Neue", "Lucida Grande",
    sans-serif;

  -webkit-appearance: none;

  border: 0;
  margin: 0 0 24px 0;

  position: relative;

  cursor: pointer;

  &:focus {
    outline: none;
  }

  transition: all 0 ease-in-out;

  &:hover {
    color: #000;
  }
`;

const PrivacyMessage = styled.div`
  width: calc(100% - 90px);
  max-width: 500px;

  font-family: "CaslonDoric-Medium", "Helvetica Neue", "Lucida Grande",
    sans-serif;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.06em;

  & a {
    text-decoration: underline;
  }

  @media (max-width: 1000px) {
    width: 100%;
  }
`;

async function userSubscribeToMailchimp(data) {
  console.log("userSubscribeToMailchimp data: ", data);
  const subscribeMailchimp = await fetch(`/api/subscribe`, {
    method: "POST",
    body: JSON.stringify({
      name: data.NAME,
      address: data.ADDRESS,
      email: data.EMAIL,
    }),
    headers: new Headers({
      "Content-Type": "application/json",
    }),
  });
  let getSubscriberData = await subscribeMailchimp;
  return getSubscriberData;
}

async function userUpdateMailchimp(data) {
  console.log("updateMailchimpSubscriber data: ", data);
  const updateMailchimpSubscriber = await fetch(`/api/update-subscriber`, {
    method: "PATCH",
    body: JSON.stringify({
      name: data.NAME,
      address: data.ADDRESS,
      email: data.EMAIL,
    }),
    headers: new Headers({
      "Content-Type": "application/json",
    }),
  });
  let getUpdateMailchimpSubscriber = await updateMailchimpSubscriber;
  return getUpdateMailchimpSubscriber;
}

async function userUpdateTagsMailchimp(data) {
  console.log("userUpdateTagsMailchimp data: ", data);
  const updateMailchimpSubscriberTags = await fetch(
    `/api/update-subscriber-tags`,
    {
      method: "POST",
      body: JSON.stringify({
        email: data.EMAIL,
        tag: data.TAG,
      }),
      headers: new Headers({
        "Content-Type": "application/json",
      }),
    }
  );
  let getUpdateMailchimpSubscriberTags = await updateMailchimpSubscriberTags;
  return getUpdateMailchimpSubscriberTags;
}

class Mailchimp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      NAME: null,
      EMAIL: null,
      ADDRESS: null,
      TAG: this.props.tag,
      hasUserSignedUp: false,
    };

    this.submitMailchimpForm = this.submitMailchimpForm.bind(this);
  }

  handleChangeMailchimp = e => {
    this.setState({
      [`${e.target.name}`]: e.target.value,
    });
  };

  async submitMailchimpForm(e) {
    e.preventDefault();

    const data = this.state;
    console.log("submitMailchimpForm data", data);
    const signupUserResult = await userSubscribeToMailchimp(data);

    if (signupUserResult.status === 200) {
      const updateUsersTags = await userUpdateTagsMailchimp(data);
      this.setState({ hasUserSignedUp: true });
    }

    if (signupUserResult.status === 400) {
      const updateUserResult = await userUpdateMailchimp(data);
      const updateUsersTags = await userUpdateTagsMailchimp(data);
      this.setState({ hasUserSignedUp: true });
    }
  }

  render(props) {
    return (
      <MailchimpFormContainer>
        {this.state.hasUserSignedUp === false && (
          <>
            <div
              className="signup-text"
              dangerouslySetInnerHTML={{
                __html: this.props.mailchimpText.signup_text.html,
              }}
            />
            <form onSubmit={this.submitMailchimpForm}>
              <InputContainer>
                <div>
                  <label htmlFor="NAME">Name *</label>
                  <input
                    type="text"
                    onChange={this.handleChangeMailchimp}
                    name="NAME"
                    required
                  />
                </div>
                <div>
                  <label htmlFor="EMAIL">Email *</label>
                  <input
                    type="email"
                    onChange={this.handleChangeMailchimp}
                    name="EMAIL"
                    required
                  />
                </div>
                <div className="address-container">
                  <label htmlFor="ADDRESS">Address</label>
                  <input
                    type="text"
                    onChange={this.handleChangeMailchimp}
                    name="ADDRESS"
                    placeholder="(Optional)"
                  />
                </div>
                <SignUpButton
                  type="submit"
                  disabled={
                    this.state.EMAIL === null && this.state.NAME === null
                  }
                >
                  Submit
                </SignUpButton>
              </InputContainer>

              <PrivacyMessage>
                We use Mailchimp as our marketing platform. By clicking above to
                subscribe, you acknowledge that your information will be
                transferred to Mailchimp for processing. Learn more about
                Mailchimp's privacy practices{" "}
                <a
                  href="https://mailchimp.com/legal/"
                  target="blank"
                  rel="noopener noreferrer"
                >
                  here
                </a>
                .
              </PrivacyMessage>
            </form>
          </>
        )}
        {this.state.hasUserSignedUp === true && (
          <ThankYouMessage>
            <div
              className="signup-text"
              dangerouslySetInnerHTML={{
                __html: this.props.mailchimpText.after_signup_text.html,
              }}
            />
          </ThankYouMessage>
        )}
      </MailchimpFormContainer>
    );
  }
}

export default Mailchimp;
