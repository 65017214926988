import React from "react";
import styled from "styled-components";
import { Carousel } from "react-responsive-carousel";
import "../styles/gallery.css";

// Components
import ThreeTwo from "./three-two";

const GalleryContainer = styled.div`
  position: relative;

  width: 100%;
  max-height: 700px;

  padding: 0;

  & .image-gallery {
    width: 100%;
    max-height: 700px;
  }

  & .carousel.carousel-slider,
  & .slider-wrapper,
  & .slider,
  & .slide {
    height: 100%;
    max-height: 700px;
  }

  & .carousel.carousel-slider .control-arrow {
    padding: 0;
    width: 120px;

    @media (max-width: 767px) {
      width: 60px;
    }
  }
`;

// const Caption = styled.div`
//   position: absolute;
//   bottom: 0;

//   width: 100%;

//   padding: 10px 20px;
//   margin: 0 auto;

//   display: flex;
//   flex-direction: row;
//   flex-wrap: wrap;
//   justify-content: flex-end;

//   & p {
//     margin: 0;

//     font-size: 12px;
//     line-height: 1;
//   }

//   & p.spacer {
//     width: 19px;
//   }
// `;

class Gallery extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentSlide: 0,
      allGalleryContent: [],
      activeSlide: null,
      totalSlides: 1,
    };
  }

  componentDidMount(props) {
    const slideTotal = this.props.data.length;

    this.setState({
      allGalleryContent: this.props.data,
      totalSlides: slideTotal,
    });
  }

  updateCurrentSlide = index => {
    const { currentSlide } = this.state;

    if (currentSlide !== index) {
      this.setState({
        currentSlide: index,
      });
    }
  };

  render() {
    const galleryContent = this.props.data
      .filter(content => content.gallery_image.url !== null)
      .map((content, index) => (
        <ThreeTwo
          image={content.gallery_image.url}
          alt={content.gallery_image.alt}
          lazy={false}
          size={800}
          padding={`20px`}
          key={`pamphlet_gallery_${index}`}
        />
      ));

    return (
      <GalleryContainer>
        <Carousel
          showThumbs={false}
          showIndicators={false}
          showStatus={false}
          infiniteLoop={true}
          useKeyboardArrows={false}
          className="image-gallery"
          selectedItem={this.state.currentSlide}
          onChange={this.updateCurrentSlide}
          // transitionTime={0}
        >
          {galleryContent}
        </Carousel>

        {/* {this.state.totalSlides > 1 && (
          <Caption>
            <p>
              {`${this.state.currentSlide + 1} / ${this.state.totalSlides}`}
            </p>
          </Caption>
        )} */}
      </GalleryContainer>
    );
  }
}

export default Gallery;
