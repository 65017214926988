import React from "react";
import styled from "styled-components";

// Styles

const ImageContainer = styled.div`
  position: relative;
  overflow: hidden;

  width: 100%;
  height: 100%;

  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 66.667%;
  }
  & > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`;

const Image = styled.img`
  position: relative;

  width: 100% !important;
  height: 100% !important;

  object-fit: contain;
  padding: 0 40px;

  z-index: 10;

  max-width: 1160px;
  max-height: 700px;

  @media (max-width: 767px) {
    padding: 0;
  }
`;

const ThreeTwo = ({
  image,
  alt,
  lazy,
  size,
  objectFit,
  backgroundColor,
  backgroundImage,
  padding,
  video,
  width,
  height,
}) => {
  return (
    <ImageContainer
      backgroundColor={backgroundColor}
      backgroundImage={backgroundImage}
      width={width}
      height={height}
    >
      <div className="content">
        <Image
          width={width}
          height={height}
          objectFit={objectFit}
          srcSet={`${image}&h=${size}&crop=entropy&q=80 1x,
          ${image}&h=${size}&crop=entropy&dpr=2&q=80 2x,
          ${image}&h=${size}&crop=entropy&dpr=3&q=80 3x`}
          src={`${image}&h=${size}&crop=entropy&q=80`}
          loading={lazy === true ? "lazy" : "auto"}
          alt={alt}
          padding={padding}
        />
      </div>
    </ImageContainer>
  );
};

export default ThreeTwo;
